
.App {
  /*text-align: center;*/
}

.detail-box {
  padding: 1.25rem;
  border-radius: 0.375rem;
  background-color: #2A3042;
  text-align: left;
  font-size: 14px;
}

.detail-box-set {
  height: 234px;
}


h6 {

}

h5 {
  font-weight: 400;
  font-size: 15px;
}

h4 {
  font-size: 20px;
  font-weight: 400;
}

.progress {
  background-color: rgba(49, 57, 76, 1);
  height: 20px;
  position: relative;
}

.progress-text {
  position: absolute;
  margin-left: 5px;
  margin-top: 2px;
  color: #ffffff;
}

th {
  i {
    float: right;
    margin-top: 3px;
    margin-right: 3px;
  }

  &.sortable {
    cursor: pointer;
  }
}