.Home {}

.negativeMarginTop {
  margin-top: -2px
}

.chainImg {
  float: left;
  margin-right: 8px;
  width: 30px;
}