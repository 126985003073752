.Bond {}

.chartContainer {
  height: 330px;
  max-height: 330px;
}

.chartInnerContainer {
  height: 300px;
  max-height: 300px;
}

