$font-color: #f6f6f6;
$font-color-alt: #a6b0cf;

$border-color: #31394D;

$border-radius: 5px;

$background-color-alt: #31394D;

$primary-alt: #6560C5;

$theme-colors: (
        "primary": $primary-alt,
);

@import '~bootstrap/scss/bootstrap.scss';