@import "variables";

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  /*font-size: 15px;*/
  font-style: normal;
  background: #222736;
  color: $font-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table {
  color: $font-color-alt;

  td, th {
    border-bottom-color: $border-color;
    padding-top: 14px;
    padding-bottom: 14px;
    background: transparent;
    color: $font-color-alt;
  }

  .highlighted-column {
    background: rgba(red($background-color-alt), green($background-color-alt), blue($background-color-alt), 0.7);
  }

  th {
    background: $background-color-alt;
    text-align: left;
  }

}

.pagination {
  margin-bottom: 0px;
  button {
    background-color: transparent;
    border-color: $border-color;
    color: $font-color-alt;
  }
  button:hover {
    background-color: $background-color-alt;
    border-color: $background-color-alt;
    color: $font-color-alt;
  }
  .page-item:first-child .page-link {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
  .page-item:last-child .page-link {
    border-top-right-radius:  $border-radius;
    border-bottom-right-radius:  $border-radius;
  }
  .active>.page-link, .page-link.active {
    background-color: $background-color-alt;
    border-color: $background-color-alt;
  }
}

input {
  border-color: $border-color !important;
  font-size: 14px !important;
}


select {
  background: $background-color-alt;
  border: 1px solid $border-color !important;
  font-size: 14px !important;
  font-weight: 300;
}


a {
  color: inherit;
  text-decoration: none;
  font-weight: 500;
}

.heading-light {
  font-weight: 300;
}

.heading-small {
  font-size: 85%;
}

.mt-negative-5 {
  margin-top: -5px;
}

@media (min-width: 768px) {
  .mt-negative-10 {
    margin-top: -10px;
  }
}

.btn-semi-transparent {
  //background: rgba(red($primary-alt), green($primary-alt), blue($primary-alt), 0.5);
  background: none;
  color: $primary-alt;
  //border-width: 2px;
  //opacity: 50%
}